@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,600;1,100;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;1,100;1,400&display=swap);
.terminal {
	border-radius: 5px 5px 0 0;
	position: relative;
	width: 1024px;
	transition: 0.5s;
	margin-top: 25px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.98);
}

.terminal .body {
	background: var(--dark-gray);
	color: var(--red);
	padding: 8px;
	overflow: auto;
	height: 350px;
	font-size: 14px;
}

.terminal .terminal-bar {
	background: var(--black);
	color: var(--text);
	padding: 5px;
	border-radius: 5px 5px 0 0;
}

.terminal .btns {
	position: absolute;
	top: 7px;
	right: 7px;
}

.terminal .circle {
	width: 14px;
	height: 14px;
	display: inline-block;
	border-radius: 15px;
	margin-left: 7px;
	border-width: 1px;
	border-style: solid;
}

.terminal-title {
	text-align: center;
}

.red {
	background: var(--red);
	border-color: var(--red);
}

.green {
	background: var(--vue);
	border-color: var(--vue);
}

.yellow {
	background: var(--yellow);
	border-color: var(--yellow);
}

.clear {
	clear: both;
}
.banner {
    position: relative;
    z-index: -1;
}

.banner-image {
    min-height: 100vh;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.banner-image:before {
    position: absolute;
    content: '';
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: bold;
}

.stack {
    display: grid;
    grid-template-columns: 1fr;
}

.stack span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 5rem;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    -webkit-clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
            clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack 340ms cubic-bezier(.46, .29, 0, 1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
    color: var(--white);
    text-shadow: 2px 3px 0 var(--black);
}

.stack span:nth-child(odd) {
    --glitch-translate: 38px;
}

.stack span:nth-child(even) {
    --glitch-translate: -38px;
}

@-webkit-keyframes stack {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    }

    60% {
        opacity: 0.5;
        -webkit-transform: translateX(50%);
                transform: translateX(50%);
    }

    80% {
        -webkit-transform: none;
                transform: none;
        opacity: 1;
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }

    100% {
        text-shadow: none;
    }
}

@keyframes stack {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    }

    60% {
        opacity: 0.5;
        -webkit-transform: translateX(50%);
                transform: translateX(50%);
    }

    80% {
        -webkit-transform: none;
                transform: none;
        opacity: 1;
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }

    100% {
        text-shadow: none;
    }
}

@-webkit-keyframes glitch {
    0% {
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
        -webkit-transform: translate(var(--glitch-translate));
                transform: translate(var(--glitch-translate));
    }

    2% {
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }

    4%, 100% {
        text-shadow: none;
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes glitch {
    0% {
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
        -webkit-transform: translate(var(--glitch-translate));
                transform: translate(var(--glitch-translate));
    }

    2% {
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }

    4%, 100% {
        text-shadow: none;
        -webkit-transform: none;
                transform: none;
    }
}

.education-container {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: start;
	align-items: center;
	align-content: center;
	grid-column-gap: 1rem;
	-webkit-column-gap: 1rem;
	        column-gap: 1rem;
}

.panel.panel-default {
    background: var(--dark-gray);
    padding: 25px;
    border-radius: 5px;
    border-left: solid 5px var(--purple);
    position: relative;
    margin-bottom: 25px;
    width: 100%;
    transition: 0.5s;
}

.panel.panel-default:hover {
    border-left: solid 5px var(--red);
}

.panel-title a {
    color: var(--text);
    text-decoration: none;
    font-size: 18px;
}
.skill-container {
	display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: auto;
    grid-row-gap: 45px;
    row-gap: 45px;
    grid-column-gap: 45px;
    -webkit-column-gap: 45px;
            column-gap: 45px;
}

.skill-item {
	background: var(--dark-gray);
	padding: 50px;
	border-radius: 5px;
	position: relative;
	text-align: center;
}

.skill-item:hover {
	cursor: pointer;
}

.skill-item i {
	color: var(--red);
}

.skill-item i:hover {
	color: var(--text);
}

.skill-content {
	text-align: center;
	font-size: 50px;
}
.timeline-centered {
    position: relative;
    margin-bottom: 30px;
}

.timeline-centered:before,
.timeline-centered:after {
    content: " ";
    display: table;
}

.timeline-centered:after {
    clear: both;
}

.timeline-centered:before,
.timeline-centered:after {
    content: " ";
    display: table;
}

.timeline-centered:after {
    clear: both;
}

.timeline-centered:before {
    content: "";
    position: absolute;
    display: block;
    width: 4px;
    background: var(--text);
    top: 20px;
    bottom: 20px;
    margin-left: 29px;
}

.timeline-centered .timeline-entry {
    position: relative;
    margin-top: 5px;
    margin-left: 30px;
    margin-bottom: 10px;
    clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner {
    position: relative;
    margin-left: -20px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
    display: block;
    width: 40px;
    height: 40px;
    background: var(--red);
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 0 0 5px var(--text);
    line-height: 40px;
    float: left;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon i {
    color: var(--text);
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
    position: relative;
    background: var(--dark-gray);
    padding: 1.5rem;
    margin-left: 80px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    border-radius: 5px;
    transition: 0.5s;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent var(--dark-gray) transparent transparent;
    left: 0;
    top: 10px;
    margin-left: -9px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p+p {
    margin-top: 15px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
    font-size: 20px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
    color: var(--text);
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
    color: var(--red);
    font-size: 16px;
}
.work-container {
	display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: auto;
    grid-row-gap: 50px;
    row-gap: 50px;
}

.work-item {
	background: var(--dark-gray);
	position: relative;
	border-radius: 5px;
	width: 250px;
	height: 250px;
}

.work-info {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	width: 100%;
	color: var(--text);
	padding: 0px 15px 0 15px;
	word-wrap: break-word;
}

.work-info h3 {
	margin-bottom: 10px;
	text-shadow: 2px 2px 0 var(--black);
}

.work-info p {
	margin-bottom: 10px;
	text-shadow: 2px 2px 0 var(--black);
}

.work-footer p i {
	font-size: 1.2rem;
}

.work-img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	pointer-events: none;
	border-radius: 4px;
	background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
	opacity: 0.3;
}
.contact-container {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: start;
	align-items: center;
	align-content: center;
	grid-column-gap: 1rem;
	-webkit-column-gap: 1rem;
	        column-gap: 1rem;
}

.panel.panel-default {
    background: var(--dark-gray);
    padding: 25px;
    border-radius: 5px;
    border-left: solid 5px var(--red);
    position: relative;
    margin-bottom: 25px;
    width: 100%;
    transition: 0.5s;
}

.panel.panel-default:hover {
    border-left: solid 5px var(--text);
}

.panel-title a {
    color: var(--text);
    text-decoration: none;
    font-size: 18px;
}
.btn-burger {
	position: absolute;
	top: 15px;
	left: 270px;
	height: 45px;
	width: 45px;
	text-align: center;
	background: var(--dark-gray);
	border-radius: 3px;
	cursor: pointer;
	transition: left 0.4s ease;
	z-index: 99999;
}

.btn-burger.click {
	left: 270px;
}

.btn-burger span {
	color: white;
	font-size: 28px;
	line-height: 45px;
}

.btn-burger.click span:before {
	content: "\f00d";
}

.sidebar {
	width: 260px;
	height: 100%;
	background-color: var(--dark-gray);
	padding: 30px 0px;
	position: fixed;
	transition: left 0.4s ease;
	left: -260px;
    top: 0;
}

.sidebar.show {
	left: 0px;
}

.sidebar ul a {
	padding: 15px;
	font-size: 16px;
	color: var(--text);
	border-bottom: 1px solid var(--text);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top: 1px solid rgba(255, 255, 255, 0.05);
	border-left: solid 5px transparent;
	width: 100%;
	text-align: left;
	text-decoration: none;
	display: block;
	transition: 0.5s;
}

.sidebar ul i {
	padding-right: 8px;
}

.sidebar ul a:hover,
ul a:active,
ul a:focus {
	border-left: solid 5px var(--red);
	color: var(--text);
	text-decoration: none;
	outline: 0;
	box-shadow: none;
}

.sidebar ul .link-active {
	border-left: solid 5px var(--red);
	color: var(--text);
}

.sidebar__avatar .me {
	margin-bottom: 30px;
}

.sidebar__avatar h1 {
	color: var(--text);
	font-size: 26px;
	font-weight: 500;
	display: block;
}

.sidebar__avatar span {
	color: var(--text);
	text-align: center;
	font-weight: 600;
	font-size: 19px;
}

.sidebar__avatar img {
	width: 140px;
	height: 140px;
	text-align: center;
	border-radius: 50%;
	margin-bottom: 30px;
}

.sidebar__avatar span {
	color: var(--red);
	transition: 0.5s;
	text-align: center;
}

@media (max-width: 360px) {}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    list-style: none;
}

body {
    background-color: #121214;
    background-color: var(--black);
    color: #E1E1E6;
    color: var(--text);
    overflow-x: hidden;
}

:root {
    --black: #121214;
    --dark-gray: #212025;
    --white: #FFFFFF;
    --text: #E1E1E6;
    --purple: #bd93f9;
    --vue: #04D361;
    --red: #FF5757;
    --npurple: #373238;
    --yellow: #f9bf3f;
    --synth-purple: #F222FF;
    --synth-blue: #011EFE;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.wrapper .main_content {
    width: calc(100% - 260px);
    margin-left: 260px;
    z-index: -1;
}

.wrapper .main_content .info {
    margin: 60px;
    color: #E1E1E6;
    color: var(--text);
    line-height: 25px;
}

.wrapper .main_content .info {
    margin-bottom: 20px;
}

.title {
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
}

.title:after {
    position: absolute;
    content: '';
    top: 30px;
    left: 0;
    border-bottom: solid 3px #FF5757;
    border-bottom: solid 3px var(--red);
    width: 130px;
    transition: .5s;
}

.btn-outline-vue,
.btn-outline-vue i {
    color: #04D361;
    color: var(--vue);
    background: transparent;
    background-image: none;
    border-color: #04D361;
    border-color: var(--vue);
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 600;
}

.btn-outline-vue:hover {
    color: #04D361;
    color: var(--vue);
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-vue {
    color: #04D361 !important;
    color: var(--vue) !important;
}

.text-purple {
    color: #bd93f9 !important;
    color: var(--purple) !important;
}

.text-red {
    color: #FF5757;
    color: var(--red);
}

.text-text {
    color: #E1E1E6;
    color: var(--text);
}

.text-white {
    color: #FFFFFF;
    color: var(--white);
}

select {
    background-color: #212025;
    background-color: var(--dark-gray);
    color: #E1E1E6;
    color: var(--text);
    border: none;
    cursor: pointer;
}

section option {
    cursor: pointer;
}

.terminal-title,
.terminal .body,
.terminal .body span,
.terminal .body a {
    font-family: 'JetBrains Mono', monospace !important;
}

.blink {
    -webkit-animation: blinker 1s infinite;
            animation: blinker 1s infinite;
}

div.title p {
    margin-top: 30px;
}

@-webkit-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media screen and (max-height: 500px) {}

@media screen and (max-width: 360px) {}

